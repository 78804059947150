import React from 'react';
import LinkArrowSvg from '../../images/link-arrow';

const Recruiting = () => {
    const recruitingList = [
        {
            title: 'Business Development Executive',
            location: 'Ahmedabad',
            expirince: '1+ Years',
            link: '/'
        },
        {
            title: 'Sr. UX Designer',
            location: 'Ahmedabad',
            expirince: '4 - 5 Years',
            link: '/'
        },
        {
            title: 'UI Designer',
            location: 'Ahmedabad',
            expirince: '1 - 2 Years',
            link: '/'
        },
        {
            title: 'Front End Developer / UI',
            location: 'Remote',
            expirince: '6 Months - 1 Year',
            link: '/'
        },
        {
            title: 'Public Relation',
            location: 'Ahmedabad',
            expirince: '6 Months +',
            link: '/'
        },
        {
            title: 'Junior Motion Designer',
            location: 'Remote',
            expirince: '1 - 2 Years',
            link: '/'
        },
        {
            title: 'Senior Motion Designer',
            location: 'Remote',
            expirince: '2 - 3 Years',
            link: '/'
        },
        {
            title: 'Junior Full Stack Developer',
            location: 'Remote',
            expirince: '2 Years',
            link: '/'
        },
        {
            title: 'Senior Full Stack Developer',
            location: 'Remote',
            expirince: '3 - 5 Years',
            link: '/'
        },
        {
            title: 'Flutter Developer',
            location: 'Remote',
            expirince: '1 - 2 Years',
            link: '/'
        },
        {
            title: 'Content Writer',
            location: 'Remote',
            expirince: '1 - 2 Years',
            link: '/'
        },
        {
            title: 'UI UX Designer',
            location: 'Ahmedabad',
            expirince: '1+ Years',
            link: '/'
        },
        {
            title: 'Marketing Co-ordinator',
            location: 'Ahmedabad',
            expirince: '1+ Years',
            link: '/'
        }
    ]
    return (
        <section className="lg:py-[200px] md:py-[120px] py-[80px]">
            <div className='container'>
                <div className='lg:mb-[100px] md:mb-[80px] mb-[40px]'>
                    <h2 className='lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[800px] md:w-[668px]'>
                        We are open and currently recruiting
                    </h2>
                    <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] md:w-[668px] lg:w-[800px] pr-[10px] md:pr-[0]'>
                        We're growing, and you can be a part of it! Join our team as we are looking for passionate candidates to work in a dynamic environment. Come be a part of our thriving community!
                    </p>
                </div>
            </div>
            <div className='career-list'>
                {recruitingList.map((item, index) => (
                    <div key={index} className='lg:pt-[30px] lg:pb-[30px] md:pt-[35px] md:pb-[20px] pt-[25px] pb-[20px] flex justify-between md:items-center recruite-item w-full flex-col md:flex-row gap-[15px] md:gap-[0] hover:bg-[#FAFBFA] transition-all duration-300 hover:transition-all hover:duration-300 '>
                        <div className='w-full'>
                            <h3 className='md:text-[22px] text-[20px] font-medium leading-[normal] md:leading-[29.2px] lg:text-[30px] lg:max-w-[60%] lg:leading-[40px] font-heading mb-[20px]'>
                                {item.title}
                            </h3>
                            <div className='flex gap-[20px]'>
                                <div className='min-w-[150px]'>
                                    <p className='lg:text-[15px] text-[#7A7A7A] text-[12px] leading-[14.52px] lg:leading-[24px] tracking-[.06em] lg:tracking-[.01em] mb-[10px]'>Location</p>
                                    <p className='lg:text-[14px] text-[#121212] text-[12px] leading-[14.52px] lg:leading-[22px] tracking-[.05em] lg:tracking-[.01em] font-medium uppercase'>{item.location}</p>
                                </div>
                                <div>
                                    <p className='lg:text-[15px] text-[#7A7A7A] text-[12px] leading-[14.52px] lg:leading-[24px] tracking-[.06em] lg:tracking-[.01em] mb-[10px]'>Experience</p>
                                    <p className='lg:text-[14px] text-[#121212] text-[12px] leading-[14.52px] lg:leading-[22px] tracking-[.05em] lg:tracking-[.01em] font-medium uppercase'>{item.expirince}</p>
                                </div>
                            </div>
                        </div>
                        <a href="mailto:hr@octet.design" className='md:min-w-[164px] min-w-[111px] relative md:font-medium lg:font-normal font-normal lg:pr-[51px] md:pr-[40px] pr-[36px] lg:text-[21px] md:text-[16px] text-[15px] md:leading-[19.36px] lg:leading-[25.41px] leading-[26px] footer-link hover:text-[#53D4B5] transition hover:transition-all duration-300 hover:duration-300 tracking-[.03em] flex items-center max-w-fit'>
                            Apply Now
                            <span className='absolute top-[50%] -translate-y-[50%] left-[calc(100%-26px)] md:left-[calc(100%-28px)] lg:left-[calc(100%-36px)] rotate-45 flex items-center justify-center h-[26px] w-[26px] md:w-[28px] md:h-[28px] lg:w-[36px] lg:h-[36px] bg-[#53D4B5] hover:transition-all transition-all duration-300 hover:duration-300 rounded-[50px]'>
                                <LinkArrowSvg size={15.58} fill='#fff' />
                            </span>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    )
}
export default Recruiting
