import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import WeArePassionate from "../components/careers/WeArePassionate";
import WhyJoinUs from "../components/careers/WhyJoinUs";
import Recruiting from "../components/careers/Recruiting";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
const Carrers = () => {
  const dynamicImages = [
    {
      title: "team-image",
      images: ["team-image-6.webp"],
      aspectRatio: "1.258 / 1",
    },
    {
      title: "team-image-2",
      images: ["team-image-73.webp"],
      aspectRatio: "1.0339 / 1",
    },
    {
      title: "team-image-2",
      images: ["team-image-74.webp"],
      aspectRatio: "1.0339 / 1",
    },
    {
      title: "team-image-2",
      images: ["team-image-75.webp"],
      aspectRatio: "1.0339 / 1",
    },
  ];

  const imageData = useStaticQuery(graphql`
    query {
      dynamicImages: allFile(
        filter: { relativePath: { regex: "/^(team-image|visa|team-image-6)/" } }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const imagesByPath = {};
  imageData.dynamicImages.edges.forEach(({ node }) => {
    const imagePath = node.relativePath;
    const imageData = getImage(node.childImageSharp);
    imagesByPath[imagePath] = imageData;
  });
  return (
    <Layout>
      <div className="service-page">
        <section className="py-[80px] md:py-[100px] lg:py-[120px] relative">
          <StaticImage
            src="../images/carrer-guides.webp"
            placeholder="none"
            alt="guides"
            className="!absolute right-[-100px] top-0"
          />
          <div className="container fade-in-animation">
            <div className="lg:mb-[120px] md:mb-[100px] mb-[80px]">
              <h1 className="first-comesup lg:text-[95px] md:text-[80px] text-[50px] leading-[1.2] md:leading-[1.3684] mb-[20px] lg:mb-[30px] font-heading font-semibold lg:max-w-[696px]">
                Careers at <br /> Octet Design
              </h1>
              <p className="second-comesup lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] md:w-[668px] lg:w-[661px] pr-[10px] md:pr-[0] mb-[20px] lg:mb-[40px]">
                We provide avenues to building a strong career toambitious
                newbies and to pros alike.
              </p>
              <StaticImage
                placeholder="none"
                src="../images/glassdoor.webp"
                alt="glassdoor"
                className="lg:w-[200px] md:w-[189px] w-[150px]"
              />
            </div>
            <div className="team-div mx-[-20px] md:mx-[calc((100vw-768px)/-2)] lg:mx-[calc((100vw-1166px)/-2)] items-center pl-[0]">
              <Swiper
                className="team-slider cursor-[url(../images/drag.webp),_move]"
                breakpoints={{
                  300: {
                    spaceBetween: 0,
                    slidesPerView: "auto",
                  },
                  768: {
                    spaceBetween: 0,
                    slidesPerView: "auto",
                  },
                  1024: {
                    spaceBetween: 0,
                    slidesPerView: "auto",
                  },
                  1200: {
                    spaceBetween: 2,
                    slidesPerView: "auto",
                  },
                }}
              >
                {dynamicImages.map(({ title, images, aspectRatio }, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className="!flex flex-col justify-between"
                    >
                      <GatsbyImage
                        className="slider-image"
                        style={{ aspectRatio: aspectRatio }}
                        image={imagesByPath[images[0]]}
                        alt={title ? title : "careers"}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
        <WeArePassionate />
        <WhyJoinUs />
        <Recruiting />
      </div>
    </Layout>
  );
};

export default Carrers;

export const Head = () => (
  <Seo
    title="UI UX Designer Jobs, Hybrid Mode | Careers at Octet"
    description="Join Octet Design Studio and be a part of thriving community building scalable, intutitive and user-friendly digital solutions. Offices in Ahmedabad & Mumbai."
  />
);
