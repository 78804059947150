import { StaticImage } from 'gatsby-plugin-image';
import React, { useState, useEffect } from 'react';

const WeArePassionate = () => {
    const [designed, setDesigned] = useState(0);
    const [worked, setWorked] = useState(0);
    const [prototypes, setPrototypes] = useState(0);
    const [projects, setProjects] = useState(0);

    useEffect(() => {
        let design = 0;
        let work = 0;
        let proto = 0;
        let project = 0;
        const interval = setInterval(() => {
            design++;
            work += 50;
            proto+= 1.7;
            project+= 1.3;
            setProjects(Math.round(project));
            setPrototypes(Math.round(proto));
            setWorked(work);
            setDesigned(design);
            
            if (work >= 8432) {
                setWorked(8432);
                setDesigned(1257);
                setPrototypes(309);
                setProjects(200);
                clearInterval(interval);
            }
        }, 40);
        return () => clearInterval(interval);
    }, []);
    const items = [
        {
            num: designed,
            text: 'Screens Designed'
        },
        {
            num: worked,
            text: 'Hours Worked'
        },
        {
            num: prototypes,
            text: 'Prototypes built'
        },
        {
            num: projects,
            text: 'Projects'
        }
    ];
    return (
        <section className='lg:py-[200px] md:py-[120px] py-[80px] bg-[#FAFBFB] w-[100%] relative overflow-hidden'>
            <StaticImage src='../../images/carrer-guides.webp' alt='guides' className='!absolute right-0 top-0' />
            <div className='container'>
                <div className='lg:mb-[100px] md:mb-[80px] mb-[60px]'>
                    <h2 className='lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[800px] md:max-w-[668px]'>
                        We are Passionate Creators & Challengers
                    </h2>
                    <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] md:w-[668px] lg:w-[538px] pr-[10px] md:pr-[0] lg:mb-[30px] mb-[20px]'>
                        We are passion fueled designers who dare to challenge design limits. We are driven by sheer determination to solve problems using design first, and take things to the next level.
                    </p>
                    <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] md:w-[668px] lg:w-[538px] pr-[10px] md:pr-[0]'>
                        We are always on the lookout for creative thinkers, people full of passion, lively zing & most importantly for motivated minds who believe in action.
                    </p>
                </div>
                <div className='flex lg:gap-[30px] md:gap-[50px] gap-[40px] flex-wrap lg:flex-nowrap items-center'>
                    {items.map((item, index) => (
                        <div className='pr-[20px] flex flex-col gap-[10px] lg:w-[calc(100%-22.5px)] md:w-[319px] w-full' key={index}>
                            <p className='font-semibold font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.08em]'>
                                {item.num}+
                            </p>
                            <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em]'>
                                {item.text}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default WeArePassionate
