import React from 'react';

const WhyJoinUs = () => {
    const items = [
        {
            title: 'Culture',
            text: 'We strive to keep a relax work environment and warm culture to keep minimum stress level.'
        },
        {
            title: 'Creative Freedom',
            text: 'Every brain has different approach towards a goal and we are always eager to hear new ideas.'
        },
        {
            title: 'Growth',
            text: 'We work on varied projects with different clients that allows us to broaden our vision.'
        },
        {
            title: 'Balance',
            text: 'Work-life balance is not a myth here, it’s a reality. And we ensure to offer you maximum balance.'
        },
        {
            title: 'Remote Working',
            text: 'We offer full freedom in choosing places where you can work from, be it office or comfort of your home.'
        },
        {
            title: 'Inclusive',
            text: 'We are an organisation that welcomes everyone as we believe that diversity breeds creativity.'
        }
    ];
    return (
        <section className="lg:py-[200px] md:py-[120px] py-[80px] bg-[#121212] w-[100%] bg-[url('../images/guides.webp')] text-white">
            <div className='container'>
                <h2 className='lg:mb-[100px] md:mb-[80px] mb-[60px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[800px] md:w-[668px]'>
                    Why Join Us?
                </h2>
                <div className='flex gap-y-[60px] gap-x-[30px] flex-wrap'>
                    {items.map((item, index) => (
                        <div className='pr-[30px] lg:w-[calc(33.33%-20px)] w-full' key={index}>
                            <h3 className='mb-[10px] leading-[27px] font-medium font-heading lg:leading-[55px] text-[20px] md:text-[28px] lg:text-[35px] md:leading-[40px]'>
                                {item.title}
                            </h3>
                            <p className='text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] lg:leading-[30px] tracking-[.02em] md:tracking-[.03em] font-light'>
                                {item.text}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default WhyJoinUs
